.co-container {
    background-color: whitesmoke;
    width: 300px;
    height: 100%;
    border: 1px solid whitesmoke;
    border-radius: 5px;
    padding: 20px;
}

.co-icon-container {
    width: 100%;
    text-align: center;
    font-size: 80px;
    color: #1386B3;
}

.co-title {
    font-weight: bold;
}

.co-row {
    z-index: 1000;
}

.co-col {
    z-index: 1000;
}