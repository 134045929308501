.admin-login-container {
    min-width: 975px;
}

.admin-login-header {
    height: 180px;
}

.admin-login-content {
    display: flex;
    flex-direction: column;
}

.admin-login-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.admin-login-h2 {
    font-size: 24px;
    color: #404040;
}

.admin-login-p {
    font-size: 1em;
    line-height: 18px;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-bottom: 9px;
    color: #404040;
}

li {
    line-height: 18px;
    color: #808080;
    margin-bottom: 8px;
}

.admin-login-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 40px;
    background-color: #00ace6;
    padding-top: 7px;
    margin-top: 50px;
    padding-left: 200px;
    padding-right: 200px;
}

.admin-login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.admin-login-field {
    display: flex;
    flex-direction: column;   
    margin-top: 20px;
}

.admin-login-field-name {
    font-weight: bold;
    font-size: 13px;
    margin-right: 20px;
}

.admin-login-field-hint {
    color: #003f81;
    font-size: 11px;
    width: 200px;
    margin-left: 20px;
}

.admin-login-text-box {
    width: 250px;
    height: 30px;
    padding: 4px;
    font-size: 13px;
    line-height: 18px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.admin-login-password-recovery {
    margin-left: 200px;
    margin-top: 40px;
}

