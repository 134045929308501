.certificado-container {
    background-color: whitesmoke;
    width: 300px;
    height: 100%;
    border: 1px solid whitesmoke;
    border-radius: 5px;
    padding: 20px;
}

.certificado-icon-container {
    width: 100%;
    text-align: center;
    font-size: 80px;
    color: #1386B3;
}

.certificado-title {
    font-weight: bold;
    text-align: center;
    width: 100%;
}

.certificado-row {
    z-index: 1000;
}

.certificado-col {
    z-index: 1000;
}