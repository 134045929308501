.title-container {
    width: 100%;
    min-width: 640px;
    /*border: 1px solid black;*/
    /*background-color: #1386B3;*/
    background-color: var(--primary-color);
    color: white;
    padding-top: 10px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
}