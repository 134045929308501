.header {
    height: 180px;
    padding-left: 200px;
    padding-top: 20px;
}

.footer-container {
    position: fixed;
    width: 100%;
    /*border: solid 1px;*/
    bottom: 0;
    z-index: 2;
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 40px;
    background-color: var(--primary-color);
    padding-top: 7px;
    /*margin-top: 50px;*/
    padding-left: 200px;
    padding-right: 200px;
}

.content {
    margin-bottom: 50px;
}

.welcome-logo {
    width: 200px;
    /*background-color: var(--primary-color);*/
    padding: 20px;
    border-radius: 5px;
}