.info-table {
    width: 100%;
}

.info-table th {
    font-size: small;
    padding-left: 0px !important;
    text-align: center;
    border-left: solid 1px whitesmoke;
}

.info-table td {
    text-align: center;
}