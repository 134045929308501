.factura-estado-icon-container {
    color: green;
    text-align: center;
    font-size: x-large;
}

.factura-estado-icon-container-uncheck {
    color: #cccccc;
    text-align: center;
    font-size: x-large;
}

.datefields-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-around;
    margin-top: 20px;
}