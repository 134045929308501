.tabla-retencion th{
    padding-left: 10px !important;
    text-align: left !important;
}

.tabla-retencion td{
    padding-left: 10px !important;
    text-align: left !important;
}

.retencion-footer-text {
    font-size: small;
}

.fecha-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 270px;
}

.retencion-fechas-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-around;
}

.retencion-radio-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /*border: 1px solid black;*/
    font-size: medium;
    width: 50%;
    min-width: 300px;
    
}

.retencion-radio-title {
    font-size: large;
    margin-bottom: 10px;
}

.retencion-radio-option {
    margin-right: 10px;
}